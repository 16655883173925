import { select , isIe, selectAll } from './utils';
import Flickity from "flickity";
import axios from "axios";

document.addEventListener("DOMContentLoaded", function () {

    const messagesSlider = select('[data-messages-slider]');
    // console.log(messagesSlider)
    if(messagesSlider !== null) {

        const formSubmitBtn = select('[data-submit-btn]')
        formSubmitBtn.addEventListener("click",function(e) {
            e.preventDefault();
            formSubmitBtn.disabled = true
            const message = select('[data-gb-msg]')
            const guestname = select('[data-gb-name]')
            const email = select('[data-gb-email]')
            
            const variables = {
                message: message.value,
                guestname: guestname.value,
                email: email.value === "" ? null : email.value
            }

            axios.get(`https://mm-guestbook.vercel.app/api/guestbook/${JSON.stringify(variables)}`)
            .then(res => {
                console.log(res)
                alert("Form submitted successfully! Thank you!")
                location.reload();

            }).catch(err => console.log(err))
        })

        axios.get("https://mm-guestbook.vercel.app/api/guestbook")
        .then(res => {
            // console.log(res)

            const msgs = res.data.guestbook;
            msgs.forEach(item => {
                const _div = document.createElement("div")
                _div.className = "carousel-cell w-full text-center"
                const _p = document.createElement("p")
                _p.innerHTML = item.message
                _p.className= "font-ks text-md"
                const _name = document.createElement("p")
                _name.className = "font-ks text-base mt-18 block"
                _name.innerHTML = "-"+item.guestname
                _p.append(_name)
                _div.append(_p)
                messagesSlider.append(_div)
            })

            if(msgs.length !== 0) {
                const flkty = new Flickity( messagesSlider, {
                    // options
                    cellAlign: 'left',
                    contain: true,
                    adaptiveHeight: true
                  });
        
                const nextSlide = select('[data-next-slide-btn]')
                const prevSlide = select('[data-prev-slide-btn]')
                if(msgs.length > 1) {
                    nextSlide.classList.remove("hidden")
                    prevSlide.classList.remove("hidden")
                }
                nextSlide.addEventListener('click',function(){
                    flkty.next()
                })
        
                prevSlide.addEventListener('click',function(){
                    flkty.previous()
                })
            }

            
        }).catch(err => {
            console.log(err)
        })

        
    }
}); 
