export const getDistance = (x1, y1, x2, y2) => {
    var a = x1 - x2;
    var b = y1 - y2;

    return Math.sqrt(a * a + b * b);
}

export const clamp = (num, min, max) => {
    return num <= min ? min : num >= max ? max : num;
}

export const lerp = (value1, value2, amount) => {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
}

export const selectAll = (selector, elm, nodeList) => {
    var root = elm ? elm : document;
    return nodeList ? root.querySelectorAll(selector) : Array.from(root.querySelectorAll(selector));
}

export const select = (selector, elm) => {
    var root = elm ? elm : document;
    return root.querySelector(selector);
}

export const progressBetween = (value, first, last) => {
    return clamp(((value - first) / (last - first)), 0, 1);
}

export const pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export const scrollTo = (elm, offset) => {
    window.dispatchEvent(new CustomEvent("scrollTo", {
        detail: {
            elm: elm,
            offset: offset,
        }
    }));
}

export const scrollToTop = () => {
    window.dispatchEvent(new CustomEvent("scrollToTop"));
}

export const scrollUpdate = () => {
    window.dispatchEvent(new CustomEvent("scrollUpdate"));
}

export const serialize = (form) => {

    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {

        var field = form.elements[i];

        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

        // If a multi-select, get all selections
        if (field.type === 'select-multiple') {
            for (var n = 0; n < field.options.length; n++) {
                if (!field.options[n].selected) continue;
                serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
            }
        }

        // Convert field data to a query string
        else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
        }
    }

    return serialized.join('&');

};

export const isMobile = () => {
    return document.querySelector('html').classList.contains('is-mobile');
}

export const isIe = () => {
    return !!window.MSInputMethodContext && !!document.documentMode;
}

export const isIPadOs = () => {

    const ua = window.navigator.userAgent;
    if (ua.indexOf('iPad') > -1) {
        return true;
    }

    if (ua.indexOf('Macintosh') > -1) {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) { }
    }

    return false;
}